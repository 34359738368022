import {action, makeObservable, observable} from "mobx";
import {BaseLoadingStore} from "app/modules/main/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients as ApiAuthClients} from "app/modules/auth/services/AxiosBaseClient";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";

export enum AdminMenu {
    Properties,
    Books,
    Calendar,
    Requests,
    Contacts = 4,
    Policies = 5,
    PropertyAmenity = 6,
    PromoCodes = 7,
    Integration= 8,
    Inventory = 9
}

export class AdminStore extends BaseLoadingStore {
    @inject apiAuthClients: ApiAuthClients;
    @inject apiClients: ApiClients;

    selectedMenu: AdminMenu = null;
    propertyParams: string[] = null;
    authenticated: boolean = false;

    constructor() {
        super();

        makeObservable(this, {
            selectedMenu: observable,

            selectMenu: action,
            init: action
        });
    }

    selectMenu = (item: AdminMenu) => this.selectedMenu = item;

    init = async () => {
        await this.wrapInitLoadingApiCall(async () => {
                [this.authenticated, this.propertyParams] = [await this.apiAuthClients.authClient.authAdminCheck(), await this.apiClients.adminPropertyClient.getPropertyInstructionParams()];
            }
        )
    }
}
