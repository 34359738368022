import {provider, useInstance} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/account/settings/DetailsStore";
import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {Loading} from "app/modules/common/layout/components/loading";
import {BoxCenter, BoxCenterLeft, Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {Box, Divider, IconButton, Stack, Typography} from "@mui/material";
import {SectionCheckBoxField, SectionTextField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";

// @ts-ignore
import IntlTelInput from "intl-tel-input/reactWithUtils";

export default provider(DetailsStore)(observer(() => {
    const store = useInstance(DetailsStore);

    useEffect(
        () => {
            store.init();
        }, []);

    if (store.isInitLoading) {
        return <Loading />;
    }

    return (<Container>
        <PageTitleDetails Title="Settings" Size={8}/>
        <ContainerDetails>
            <SectionName title="Personal Info" description="Here you can update your personal information." />
            <Section>
                {!store.updateFullName && <Stack spacing={2} direction={"row"}>
                    <BoxCenterLeft><Typography variant={"subtitle2"} textAlign={"left"}>{store.details.fullName || "None"}</Typography></BoxCenterLeft>
                    <Box><IconButton color="primary" title="Edit" onClick={store.onUpdateFullName}>
                        <CreateIcon fontSize={"small"}/>
                    </IconButton></Box>
                </Stack>}
                {store.updateFullName && <Stack spacing={2} direction={"row"}>
                    <SectionTextField
                        label={"Full Name"}
                        field={store.formFullNameState.$.fullName}
                    />
                    <BoxCenterLeft>
                        <BoxCenter>
                            <LoadingButton loading={store.isLoading} disabled={store.formFullNameState.hasError} size={"small"} variant="text" onClick={store.onUpdatingFullName}>
                                Update
                            </LoadingButton>
                        </BoxCenter>
                        <BoxCenter>
                            <IconButton color="error" title={"Cancel"} onClick={store.cancelUpdateFullName}>
                                <CloseIcon fontSize={"small"}/>
                            </IconButton>
                        </BoxCenter>
                    </BoxCenterLeft>
                </Stack>}
            </Section>
            <SectionSeparator/>
            <SectionName title="Email" description="Here you can change email and notification preferences." />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    {!store.updateEmail && <Stack spacing={2} direction={"row"}>
                        <BoxCenterLeft><Typography variant={"subtitle2"} textAlign={"left"}>{store.details.email || "None"}</Typography></BoxCenterLeft>
                        {!store.details.newEmail?.length && <Box><IconButton color="primary" title="Edit" onClick={store.onUpdateEmail}>
                            <CreateIcon fontSize={"small"}/>
                        </IconButton></Box>}
                    </Stack>}
                    {store.details.newEmail?.length > 0 && <Box>
                        <Divider/>
                        <BoxCenterLeft sx={{ mt: 1 }}><Typography variant={"subtitle2"} textAlign={"left"}>Pending Update: {store.details.newEmail}</Typography></BoxCenterLeft>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                            <SectionTextField
                                label={"Verification Code"}
                                field={store.formEmailVerificationState.$.verificationCode}
                            />
                            <BoxCenterLeft>
                                <BoxCenter>
                                    <LoadingButton loading={store.isLoading} disabled={store.formEmailVerificationState.hasError} size={"small"} variant="text" onClick={store.onVerifyEmail}>
                                        Verify
                                    </LoadingButton>
                                </BoxCenter>
                                <BoxCenter>
                                    <IconButton color="error" title={"Cancel"} onClick={store.onCancelUpdatingEmail}>
                                        <CloseIcon fontSize={"small"}/>
                                    </IconButton>
                                </BoxCenter>
                            </BoxCenterLeft>
                        </Stack>
                    </Box>}
                    {store.updateEmail && <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                        <SectionTextField
                            label={"New Email"}
                            field={store.formEmailState.$.email}
                            type={"email"}
                        />
                        <BoxCenterLeft>
                            <Stack spacing={2} direction={"row"} height={"100%"}>
                                <BoxCenter>
                                    <LoadingButton loading={store.isLoading} disabled={store.formEmailState.hasError} size={"small"} variant="text" onClick={store.submitUpdateEmail}>
                                        Update
                                    </LoadingButton>
                                </BoxCenter>
                                <BoxCenter>
                                    <IconButton color="error" title={"Cancel"} onClick={store.cancelUpdateEmail}>
                                        <CloseIcon fontSize={"small"}/>
                                    </IconButton>
                                </BoxCenter>
                            </Stack>
                        </BoxCenterLeft>
                    </Stack>}
                    <Divider/>
                     <Stack direction={"column"}>
                        <Typography variant={"subtitle2"} textAlign={"left"}>Notification Preferences:</Typography>
                        <SectionCheckBoxField
                            field={store.formEmailPreferencesState.$.emailReservationNotifications}
                            label="Reservation Notifications"
                            isReadOnlyMode={true}
                        />
                        <SectionCheckBoxField
                            field={store.formEmailPreferencesState.$.emailNewsNotifications}
                            label="News, Discounts, Promo Codes"
                        />
                        <BoxCenterLeft>
                             <LoadingButton loading={store.isLoading} fullWidth={false} size={"small"} variant="text" onClick={store.updateEmailPreferences}>
                                 Update
                             </LoadingButton>
                         </BoxCenterLeft>
                    </Stack>
                </Stack>
            </Section>
            <SectionSeparator/>
            <SectionName title="Phone" description="We require a verified phone number on your profile to contact you with questions about the booked reservation. And reservation notifications that can be opted out." />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    {!store.updatePhone && <Stack spacing={2} direction={"row"}>
                        <BoxCenterLeft><Typography variant={"subtitle2"} textAlign={"left"}>{store.details.phone || "None"}</Typography></BoxCenterLeft>
                        {!store.details.newPhone?.length && <Box><IconButton color="primary" title="Edit" onClick={store.onUpdatePhone}>
                            <CreateIcon fontSize={"small"}/>
                        </IconButton></Box>}
                    </Stack>}
                    {store.details.newPhone?.length > 0 && <Box>
                        <Divider/>
                        <BoxCenterLeft sx={{ mt: 1 }}><Typography variant={"subtitle2"} textAlign={"left"}>Pending Update: {store.details.newPhone}</Typography></BoxCenterLeft>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                            <SectionTextField
                                label={"Verification Code"}
                                field={store.formPhoneVerificationState.$.verificationCode}
                            />
                            <BoxCenterLeft>
                                <BoxCenter>
                                    <LoadingButton loading={store.isLoading} disabled={store.formPhoneVerificationState.hasError} size={"small"} variant="text" onClick={store.onVerifyPhone}>
                                        Verify
                                    </LoadingButton>
                                </BoxCenter>
                                <BoxCenter>
                                    <IconButton color="error" title={"Cancel"} onClick={store.onCancelUpdatingPhone}>
                                        <CloseIcon fontSize={"small"}/>
                                    </IconButton>
                                </BoxCenter>
                            </BoxCenterLeft>
                        </Stack>
                    </Box>}
                    {store.updatePhone && <Stack spacing={2} direction='column'>
                        <BoxCenterLeft>
                            <Typography variant={"subtitle2"} textAlign={"left"}>
                                By providing a phone number, you agree to receive a call to confirm your reservation and clarify the details of your stay. The agent may contact you only when you make a reservation; all communication will be only for the current reservation.
                            </Typography></BoxCenterLeft>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                            <IntlTelInput
                                onChangeNumber={store.setPhoneNumber}
                                onChangeValidity={store.changePhoneNumberValidity}
                                initOptions={{
                                    initialCountry: "us",
                                    separateDialCode: true,
                                    strictMode: true,
                                    formatAsYouType: true
                                }}
                            />
                            <BoxCenterLeft>
                                <Stack spacing={2} direction={"row"} height={"100%"}>
                                    <BoxCenter>
                                        <LoadingButton loading={store.isLoading} disabled={store.formPhoneState.hasError} size={"small"} variant="text" onClick={store.submitUpdatePhone}>
                                            Update
                                        </LoadingButton>
                                    </BoxCenter>
                                    <BoxCenter>
                                        <IconButton color="error" title={"Cancel"} onClick={store.cancelUpdatePhone}>
                                            <CloseIcon fontSize={"small"}/>
                                        </IconButton>
                                    </BoxCenter>
                                </Stack>
                            </BoxCenterLeft>
                        </Stack>
                    </Stack>}
                     {store.details.phone && <Stack direction={"column"}>
                         <Typography variant={"subtitle2"} textAlign={"left"}>Notification Preferences:</Typography>
                         <SectionCheckBoxField
                             field={store.formPhonePreferencesState.$.phoneReservationNotifications}
                             label="Reservation Notifications"
                         />
                         <SectionCheckBoxField
                             field={store.formPhonePreferencesState.$.phoneNewsNotifications}
                             label="News, Discounts, Promo Codes"
                         />
                         <BoxCenterLeft>
                             <LoadingButton loading={store.isLoading} fullWidth={false} size={"small"} variant="text" onClick={store.updatePhonePreferences}>
                                 Update
                             </LoadingButton>
                         </BoxCenterLeft>
                     </Stack>}
                </Stack>
            </Section>
        </ContainerDetails>
    </Container>);
}));
