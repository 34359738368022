import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {email, required} from "app/modules/common/form/validators";
import {AccountDetails, AccountNotificationPreferenceModel} from "app/modules/main/api/main-clients.api";
import Events from "app/modules/common/static/EventNames";
import PubSub from 'pubsub-js';

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    details: AccountDetails = null;

    formFullNameState = new FormState({
        fullName: new FieldState<string>(null).validators(required),
    });

    formEmailState = new FormState({
        email: new FieldState<string>(null).validators(email, required),
    });

    formPhoneState = new FormState({
        phone: new FieldState<string>(null).validators(required),
    });

    formPhonePreferencesState = new FormState({
        phoneReservationNotifications: new FieldState(false).validators(required),
        phoneNewsNotifications: new FieldState(false).validators(required),
    });

    formEmailPreferencesState = new FormState({
        emailReservationNotifications: new FieldState(true).validators(required),
        emailNewsNotifications: new FieldState(false).validators(required)
    });

    formPhoneVerificationState = new FormState({
        verificationCode: new FieldState('').validators(required),
    });

    formEmailVerificationState = new FormState({
        verificationCode: new FieldState('').validators(required)
    });

    updatePhone: boolean = false;
    updateEmail: boolean = false;
    updateFullName: boolean = false;

    constructor() {
        super();

        makeObservable(this, {
            details: observable,
            updatePhone: observable,
            updateEmail: observable,
            updateFullName: observable,

            onUpdateEmail: action,
            onUpdatePhone: action,
            cancelUpdateEmail: action,
            cancelUpdatePhone: action,
            cancelUpdateFullName: action,
            onVerifyEmail: action,
            onVerifyPhone: action,
            onCancelUpdatingEmail: action,
            onCancelUpdatingPhone: action,
            submitUpdateEmail: action,
            submitUpdatePhone: action,
            updateEmailPreferences: action,
            updatePhonePreferences: action,
            onUpdateFullName: action,
            onUpdatingFullName: action,
            setPhoneNumber: action,
            changePhoneNumberValidity: action,
            refreshData: action,
            init: action
        });
    }

    onUpdateFullName = () => {
        this.updateFullName = true;
    }

    onUpdateEmail = () => {
        this.updateEmail = true;
    }

    onUpdatePhone = () => {
        this.updatePhone = true;
    }

    onVerifyEmail = async () => {
        await this.formEmailVerificationState.validate();

        if(this.formEmailVerificationState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.accountClient.verifyEmail(this.formEmailVerificationState.$.verificationCode.value);

            runInAction(() => {
                this.formEmailVerificationState.$.verificationCode.onChange("");
                this.refreshData();
                PubSub.publish(Events.UpdateUserData);
            })
        });
    }

    onVerifyPhone = async () => {
        await this.formPhoneVerificationState.validate();

        if(this.formPhoneVerificationState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.accountClient.verifyPhone(this.formPhoneVerificationState.$.verificationCode.value);

            runInAction(() => {
                this.formPhoneVerificationState.$.verificationCode.onChange("");
                this.refreshData();
                PubSub.publish(Events.UpdateUserData);
            })
        });
    }

    onUpdatingFullName = async () => {
        await this.formPhoneVerificationState.validate();

        if(this.formFullNameState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.accountClient.updateFullName(this.formFullNameState.$.fullName.value);

            runInAction(() => {
                this.details.fullName = this.formFullNameState.$.fullName.value;
                this.formFullNameState.$.fullName.onChange("");
                this.updateFullName = false;
            });

            PubSub.publish(Events.UpdateUserData);
        });
    }

    onCancelUpdatingEmail = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.accountClient.cancelEmailUpdate();

            runInAction(() => {
                this.refreshData();
            });
        });
    }

    onCancelUpdatingPhone = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.accountClient.cancelUpdatePhone();

            runInAction(() => {
                this.refreshData();
            });
        });
    }

    submitUpdateEmail = async () => {
        await this.formEmailState.validate();

        if(this.formEmailState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.accountClient.updateEmail(this.formEmailState.$.email.value);

            runInAction(() => {
                this.formEmailState.$.email.onChange("");
                this.updateEmail = false;

                this.refreshData();
            });
        });
    }

    setPhoneNumber = (number) => {
        this.formPhoneState.$.phone.onChange(number);
    }

    changePhoneNumberValidity = (valid) => {
        this.formPhoneState.$.phone.error = valid ? null : "Phone Number is invalid."

        console.log(this.formPhoneState.$.phone);
    }

    submitUpdatePhone = async () => {
        await this.formPhoneState.validate();

        if(this.formPhoneState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.accountClient.updatePhone(this.formPhoneState.$.phone.value);

            runInAction(() => {
                this.formPhoneState.$.phone.onChange("");
                this.updatePhone = false;
                this.updateEmail = false;

                this.refreshData();
            });
        });
    }

    cancelUpdateEmail = () => {
        this.formEmailState.$.email.onChange("");
        this.updateEmail = false;
    }

    cancelUpdatePhone = () => {
        this.formPhoneState.$.phone.onChange(null);
        this.updatePhone = false;
    }

    cancelUpdateFullName = () => {
        this.formFullNameState.$.fullName.onChange("");
        this.updateFullName = false;
    }

    updateEmailPreferences = async () => {
        await this.formEmailPreferencesState.validate();

        if(this.formEmailPreferencesState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.accountClient.updateEmailPreferences(this.formEmailPreferencesState.$.emailNewsNotifications.value);
        });
    }

    updatePhonePreferences = async () => {
        await this.formPhonePreferencesState.validate();

        if(this.formPhonePreferencesState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.accountClient.updatePhonePreferences(this.formPhonePreferencesState.$.phoneNewsNotifications.value, this.formPhonePreferencesState.$.phoneReservationNotifications.value);
        });
    }

    init = async () => {
        await this.wrapInitLoadingApiCall(async () => {
            let accountNotificationPreferenceModel: AccountNotificationPreferenceModel = null;
            [this.details, accountNotificationPreferenceModel] = [
                await this.apiClients.accountClient.getAccountDetails(),
                await this.apiClients.accountClient.getAccountNotificationPreferences()
            ];

            this.formPhonePreferencesState.$.phoneNewsNotifications.value = accountNotificationPreferenceModel.phoneNewsNotifications;
            this.formPhonePreferencesState.$.phoneReservationNotifications.value = accountNotificationPreferenceModel.phoneReservationNotifications;
            this.formEmailPreferencesState.$.emailNewsNotifications.value = accountNotificationPreferenceModel.emailNewsNotifications;
        });
    }

    refreshData = async () => {
        await this.wrapLoadingApiCall(async () => {
            [this.details] = [
                await this.apiClients.accountClient.getAccountDetails()
            ];
        });
    }
}
