import {provider, useInstance} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/account/settings/DetailsStore";
import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {BoxCenter, BoxCenterLeft} from "app/modules/common/layout/components/containers";
import {Box, Divider, IconButton, Stack, Typography} from "@mui/material";
import {SectionTextField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import {Loading} from "app/modules/common/layout/components/loading";

// @ts-ignore
import IntlTelInput from "intl-tel-input/reactWithUtils";

export default provider(DetailsStore)(observer(() => {
    const store = useInstance(DetailsStore);

    useEffect(
        () => {
            store.updatePhone = true;
            store.init();
        }, []);

    if (store.isInitLoading) {
        return <Loading />;
    }

    return (<Stack spacing={2} direction={"column"}>
        {store.details.newPhone?.length > 0 && <Box>
            <Divider/>
            <BoxCenterLeft sx={{ mt: 1 }}><Typography variant={"subtitle2"} textAlign={"left"}>Pending Update: {store.details.newPhone}</Typography></BoxCenterLeft>
            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                <SectionTextField
                    label={"Verification Code"}
                    field={store.formPhoneVerificationState.$.verificationCode}
                />
                <BoxCenterLeft>
                    <BoxCenter>
                        <LoadingButton loading={store.isLoading} disabled={store.formPhoneVerificationState.hasError} size={"small"} variant="text" onClick={store.onVerifyPhone}>
                            Verify
                        </LoadingButton>
                    </BoxCenter>
                    <BoxCenter>
                        <IconButton color="error" title={"Cancel"} onClick={store.onCancelUpdatingPhone}>
                            <CloseIcon fontSize={"small"}/>
                        </IconButton>
                    </BoxCenter>
                </BoxCenterLeft>
            </Stack>
        </Box>}

        {!store.details.newPhone?.length && store.updatePhone && <Stack spacing={2} direction={'column'}>
            <BoxCenterLeft><Typography variant={"subtitle2"} textAlign={"left"}>By providing a phone number, you agree to receive a call to confirm your reservation and clarify the details of your stay. The agent may contact you only when you make a reservation; all communication will be only for the current reservation.</Typography></BoxCenterLeft>
            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                <IntlTelInput
                    onChangeNumber={store.setPhoneNumber}
                    onChangeValidity={store.changePhoneNumberValidity}
                    initOptions={{
                        initialCountry: "us",
                        separateDialCode: true,
                        strictMode: true,
                        formatAsYouType: true
                    }}
                />
                <BoxCenterLeft>
                    <Stack spacing={2} direction={"row"} height={"100%"}>
                        <BoxCenter>
                            <LoadingButton loading={store.isLoading} disabled={store.formPhoneState.hasError} size={"small"} variant="text" onClick={store.submitUpdatePhone}>
                                Update
                            </LoadingButton>
                        </BoxCenter>
                    </Stack>
                </BoxCenterLeft>
            </Stack>
        </Stack>}
    </Stack>);
}));
