import {
    Box,
    Checkbox, Chip,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    InputLabel,
    MenuItem, OutlinedInput,
    Select, Switch, TextareaAutosize,
    TextField
} from "@mui/material";
import React from "react";
import {FieldState} from "formstate";
import {observer} from "mobx-react";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {NumericFormat, NumericFormatProps} from "react-number-format";

export const SectionTextField = observer((props: { fullWidth?: boolean, field: FieldState<any>, isReadOnlyMode?: boolean, label: string, width?: string, type?: React.InputHTMLAttributes<unknown>['type'], onChange?: () => void }) => {
    return <TextField
        sx={{ width: props.width || null }}
        fullWidth={props.fullWidth || false}
        value={(props.field.value || '')}
        InputProps={{
            readOnly: props.isReadOnlyMode || false,
        }}
        error={props.field.hasError}
        helperText={props.field.error}
        onChange={(e)=> {
            if(props.type == "number") {
                props.field.onChange(parseFloat(e.target.value));
            } else {
                props.field.onChange(e.target.value);
            }
            props.onChange && props.onChange();
        }}
        label={props.label}
        type={props.type}
        variant="standard"
    />;
})

interface NumericFormatCustomProps {
    onChange: (event: { target: { value: string } }) => void;
}

export const NumericFormatCustom = React.forwardRef<NumericFormatProps, NumericFormatCustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                thousandSeparator
                valueIsNumericString
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value,
                        },
                    });
                }}
            />
        );
    },
);

export const SectionNumberTextField = observer((props: { fullWidth?: boolean, field: FieldState<any>, isReadOnlyMode?: boolean, label: string, width?: string, prefix?: string, type?: React.InputHTMLAttributes<unknown>['type'], onChange?: () => void }) => {
    return <TextField
        sx={{ width: props.width || null }}
        fullWidth={props.fullWidth || false}
        value={(props.field.value || '')}
        InputProps={{
            inputComponent: NumericFormatCustom as any,
        }}
        inputProps={{
            prefix: props.prefix,
            readOnly: props.isReadOnlyMode || false,
        }}
        error={props.field.hasError}
        helperText={props.field.error}
        onChange={(e)=> {
            if(props.type == "number") {
                props.field.onChange(parseFloat(e.target.value));
            } else {
                props.field.onChange(e.target.value);
            }
            props.onChange && props.onChange();
        }}
        label={props.label}
        type={props.type}
        variant="standard"
    />;
})

export const SectionTextAreaField = observer((props: { fullWidth?: boolean, field: FieldState<any>, label: string, isReadOnlyMode?: boolean, width?: string, onChange?: () => void }) => {
    return <TextareaAutosize
        minRows={5}
        aria-label={props.label}
        readOnly={props.isReadOnlyMode || false}
        style={{ width: props.width || "100%" }}
        value={(props.field.value || '')}
        onChange={(e)=> {
            props.field.onChange(e.target.value);
            props.onChange && props.onChange();
        }}
    />;
})

export const SectionSelectField = observer((props: {
    field: FieldState<any>,
    isReadOnlyMode?: boolean,
    label: string,
    width?: string,
    none?: boolean,
    items: () => React.ReactNode,
    onChange?: () => void,
    variant?: 'standard' | 'outlined' | 'filled',
    array?: boolean,
    hasError?: boolean
}) => {
    return <FormControl variant={props.variant || "standard"} sx={{ width: props.width || null }} error={props.field.hasError || props.hasError}>
        <InputLabel>{props.label}</InputLabel>
        <Select
            value={props.field.value || ""}
            readOnly={props.isReadOnlyMode || false}
            onChange={(e)=>{ props.field.onChange(props.array ? [e.target.value] : e.target.value); props.onChange && props.onChange(); }}
            error={props.field.hasError || props.hasError}
            label={props.label}
        >
            {props.none && <MenuItem value=""><em>None</em></MenuItem>}
            {props.items()}
        </Select>
        {props.field.hasError && <FormHelperText>{props.field.error}</FormHelperText>}
    </FormControl>;
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, values: string[]) {
    return {
        fontWeight:
            values.indexOf(name) === -1
                ? 500
                : 200,
    };
}

export const SectionSelectChipsField = observer((props: {
    field: FieldState<any>,
    isReadOnlyMode?: boolean,
    label: string, width?: string,
    none?: boolean,
    onChange?: () => void,
    items: { id, name }[]
}) => {
    return <FormControl sx={{ m: 1, width: props.width || null }}>
        <InputLabel>{props.label}</InputLabel>
        <Select
            multiple
            value={props.field.value || ""}
            onChange={(e)=>{ props.field.onChange(e.target.value); props.onChange && props.onChange(); }}
            label={props.label}
            input={<OutlinedInput label={props.label} />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((item) => {
                        let value = props.items.find(x => x.id == item);
                        return <Chip key={value.id}
                                     label={value.name}
                                     sx={{ margin: "2px" }}
                                     onMouseDown={e => { e.stopPropagation()}}
                                     onDelete={() =>{
                                         props.field.onChange(props.field.value.filter(v => v != value.id));
                                         props.onChange && props.onChange();
                                     }}/>
                    })}
                </Box>
            )}
            MenuProps={MenuProps}
        >
            {props.items.map((x) => (
                <MenuItem
                    key={x.id}
                    value={x.id}
                    style={getStyles(x.name || '', props.field.value || [])}
                >{x.name}</MenuItem>
            ))}
        </Select>
    </FormControl>;
})

export const SectionDatePickerField = observer((props: { field: FieldState<any>, isReadOnlyMode?: boolean, label: string, width?: string, minDate?: Date, onChange?: () => void }) => {
    return <DesktopDatePicker
        label={props.label}
        value={props.field.value}
        readOnly={props.isReadOnlyMode || false}
        minDate={props.minDate}
        onChange={(newValue) => {
            props.field.onChange(newValue); props.onChange && props.onChange();
        }}
        key={props.field.hasError ? 1 : 0}
        renderInput={(params) => <TextField
            {...params}
            sx={{ width: props.width || null }}
            error={props.field.hasError}
            helperText={props.field.error}
            variant="standard"
        />}
    />;
})

export const SectionCheckBoxField = observer((props: { field: FieldState<boolean>, isReadOnlyMode?: boolean, label: string, width?: string, onChange?: () => void }) => {
    return <FormControlLabel
        disabled={props.isReadOnlyMode || false}
        sx={{ width: props.width || null }}
        control={<Checkbox checked={props.field.value} />}
        onChange={(e, checked) => {
            props.field.onChange(checked); props.onChange && props.onChange();
        }}
        label={props.label}

    />;
})

export const SectionToggleField = observer((props: { field: FieldState<boolean>, isReadOnlyMode?: boolean, label: string }) => {
    return <FormGroup>
        <FormControlLabel control={<Switch
            checked={props.field.value}
            readOnly={props.isReadOnlyMode || false}
            onChange={async (event: any) => { props.field.onChange(event.target.checked) }}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />} label={props.label} />
    </FormGroup>;
})
