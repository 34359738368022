import React from "react";
import {observer} from "mobx-react";
import {FieldState, FormState} from "formstate";
import {useState} from "react";
import {intPositive, required, requiredNumberMax} from "app/modules/common/form/validators";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";
import {InventoryItemUpdateStockModel} from "app/modules/main/api/admin-clients.api";
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {SectionNumberTextField, SectionTextField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";

export interface DialogStockChangeProps {
    closeModal: () => void;
    stockUpdated: () => void;
    isAdd: boolean;
    inventoryId: number,
    currentStock: number;
}

export const DialogStockChange = observer((props: DialogStockChangeProps) => {
    const [formState, _] = useState(new FormState({
        qty: new FieldState<number>(null).validators(required, props.isAdd ? intPositive : requiredNumberMax(props.currentStock, "Can't remove more than in the stock")),
        notes: new FieldState<string>(null),
    }))

    const [isLoading, setLoading] = useState(false);

    const store = useInstance(ApiClients);

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            await store.inventoryClient.updateStock(new InventoryItemUpdateStockModel({
                changeQty: formState.$.qty.value * (props.isAdd ? 1 : -1),
                notes: formState.$.notes.value,
                id: props.inventoryId
            }))

            props.stockUpdated();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {(props.isAdd ? "Add items to the stock" : "Remove items from the stock")}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#f0f0f0" }}>
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                <Stack spacing={2} direction={"column"}>
                    <SectionNumberTextField
                        label={(props.isAdd ? "Add Qty" : `Qty (Max: ${props.currentStock})`)}
                        field={formState.$.qty}
                        fullWidth
                    />
                    <SectionTextField
                        label={"Note"}
                        field={formState.$.notes}
                        fullWidth
                    />
                </Stack>
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>
})
