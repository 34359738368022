import {BaseLoadingStore} from "app/modules/main/stores/BaseLoadingStore";
import {action, makeObservable, observable} from "mobx";
import {inject} from "react-ioc";
import {CurrentBookStore} from "app/modules/main/stores/CurrentBookStore";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {BookingInfo, FinishBookingModel} from "app/modules/main/api/main-clients.api";
import {FieldState, FormState} from "formstate";
import {required} from "app/modules/common/form/validators";
import {MainStore} from "app/modules/main/stores/MainStore";

import {loadStripe} from '@stripe/stripe-js';
import {Stripe} from "@stripe/stripe-js/dist/stripe-js/stripe";
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";
import {ApiErrorTryGetMessage} from "app/modules/common/static/ErrrorHandler";

export enum Step {
    Contact = 1,
    Terms = 2,
    Payment = 3,
    Complete = 4
}

export class BookStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;
    @inject currentBookStore: CurrentBookStore;
    @inject mainStore: MainStore;
    @inject analyticsService: AnalyticsService;

    bookInfo: BookingInfo = null;

    currentStep = Step.Contact;
    goToSearch: boolean = false;

    stripePromise : Promise<Stripe | null> = null;
    paymentIntentClientSecret: string = null;

    bookId: string = null;
    confirmationCode: string = null;

    instantBooking: boolean = true;

    formState = new FormState({
        firstName: new FieldState('').validators(required),
        lastName: new FieldState('').validators(required),
    });

    formPromoCodeState = new FormState({
        promoCode: new FieldState('').validators(required)
    });

    errorMessage: string = null;

    constructor() {
        super();

        makeObservable(this, {
            currentStep: observable,
            goToSearch: observable,
            bookInfo: observable,
            bookId: observable,
            confirmationCode: observable,
            instantBooking: observable,
            errorMessage: observable,

            finishContactStep: action,
            backToContactStep: action,
            setGoToSearch: action,
            init: action,
            applyPromoCode: action
        });
    }

    setGoToSearch = () => this.goToSearch = true;

    finishContactStep = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        this.currentStep = Step.Terms;
    }

    acceptTermsAndComplete = async () => {
        this.confirmationCode = await this.apiClients.propertyMainClient.finishBooking(this.bookId, this.getFinishBookingModel());

        this.currentStep = Step.Complete;
    }

    getFinishBookingModel = () => new FinishBookingModel({
        firstName: this.formState.$.firstName.value,
        lastName: this.formState.$.lastName.value
    });

    acceptTermsAndGoToPayment = async () => {
        this.currentStep = Step.Payment;
    }

    initPayment = async () => {
        try {
            this.confirmationCode = await this.apiClients.paymentClient.initPayment(this.bookId, this.getFinishBookingModel());
            this.paymentIntentClientSecret = await this.apiClients.paymentClient.createPaymentIntent(this.bookId);
            this.analyticsService.paymentIntentBookingProperty(this.bookInfo.details.id, this.confirmationCode);
        } catch (ex) {
            this.errorMessage = ApiErrorTryGetMessage(ex) || "An unexpected error occurred, please try later or contact Customer Support.";
        }
    }

    backToContactStep = () => {
        this.currentStep = Step.Contact;
    }

    backToTermsStep = () => {
        this.currentStep = Step.Terms;
    }

    applyPromoCode = async () => {
        await this.formPromoCodeState.validate();

        if(this.formPromoCodeState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            this.bookInfo = await this.apiClients.propertyMainClient.applyPromoCodeBooking(this.bookId, this.formPromoCodeState.$.promoCode.value);
        });
    }

    init = async () => {
        this.bookId = this.currentBookStore.getBookId();

        await this.wrapInitLoadingApiCall(async () => {
            if(!this.bookId) {
                return;
            }

            this.bookInfo = await this.apiClients.propertyMainClient.getBookingInfo(this.bookId)

            if(!this.bookInfo?.details) {
                return;
            }

            let stripePublishableKey = await this.apiClients.paymentClient.stripeConfig(this.bookInfo.details.id);

            this.stripePromise = loadStripe(stripePublishableKey);

            let splitFullName = this.mainStore.userData.name.split(" ");

            this.formState.$.firstName.value = splitFullName[0];
            this.formState.$.lastName.value = splitFullName[1];

            if(this.bookInfo == null) {
                this.bookId = null;
            }
        });
    }
}
