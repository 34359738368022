import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {ApiClients as ApiAuthClients} from "app/modules/auth/services/AxiosBaseClient";
//import {PusherTenantService} from "app/modules/common/pusher/PusherTenantService";
import {BaseLoadingStore} from "app/modules/main/stores/BaseLoadingStore";
import {ClientData} from "app/modules/main/api/main-clients.api";
import {UserAuthData} from "app/modules/auth/api/clients.api";

export class MainStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;
    @inject apiAuthClients: ApiAuthClients;
    //@inject pusherTenantService: PusherTenantService;

    userData: UserAuthData = null;
    clientData: ClientData = null;

    constructor() {
        super();

        makeObservable(this, {
            userData: observable,
            clientData: observable,

            init: action,
            logout: action
        });
    }

    async init() {
        await this.wrapInitLoadingApiCall(async () => {
            [this.userData, this.clientData] = [
                await this.apiAuthClients.authClient.authCheck(),
                await this.apiClients.dataClient.getClientData()
            ];

            if(this.userData.isAuthenticated) {
                (window as any).Intercom('boot', {
                    app_id: 'n4q2yp3u',
                    name: this.userData.name,
                    email: this.userData.email,
                    user_id: this.userData.id,
                    created_at: 1234567890
                });
            } else {
                (window as any).Intercom('boot', {
                    app_id: 'n4q2yp3u'
                });
            }

            //this.pusherTenantService.init(this.userData.pusherSettings);
        }, null, () => {
            runInAction(() => {
                this.isInitLoading = false;
            });
        });
    }

    refreshUserInfo = async () => {
        this.userData = await this.apiAuthClients.authClient.authCheck();
    }

    async logout() {
        await this.apiAuthClients.authClient.logout();
        window.location.reload();
    }
}
