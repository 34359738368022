import axios from "axios";

import {
    IAuthClient,
    AuthClient
} from "../api/clients.api";
import {ApiClientConfig} from "app/modules/main/api/ApiClientConfig";

const hostName = window.location.hostname;
export const apiBaseUrl = hostName == 'localhost' || hostName == 'desktop-4fa3qhv' ? 'http://localhost:5000' : 'https://solartgroupinc.com';

export class ApiClients {
    authClient: IAuthClient = null;

    constructor(configuration: ApiClientConfig) {
        let axiosInstance = axios.create({
            baseURL: apiBaseUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            validateStatus: (status) => {
                return status >= 200 && status < 500
            }
        });

        axiosInstance.defaults.withCredentials = true;

        this.authClient = new AuthClient(configuration, apiBaseUrl, axiosInstance);

        axiosInstance.interceptors.response.use((response) => {
            if(response?.status == 403) {
                window.location.href = "/";
            }

            if(response?.status == 401) {
                window.location.href = "/login";
            }

            return response;
        }, (error) => {
            if(error.response?.status == 401) {
                window.location.href = "/login";
            }

            if(error.response?.status == 403) {
                window.location.href = "/";
            }
        });
    }
}

