import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {int, required} from "app/modules/common/form/validators";
import {action, makeObservable, observable, runInAction} from "mobx";
import {
    InventoryItemDetailsModel, InventoryItemEditModel, InventoryItemNewModel,
    UnitOfMeasurementType
} from "app/modules/main/api/admin-clients.api";

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    name: string = null;

    inventoryItemId: number = null;
    unitOfMeasurementType: Map<UnitOfMeasurementType, string> = new Map<UnitOfMeasurementType, string>([
        [UnitOfMeasurementType.Count, "Qty"],
        [UnitOfMeasurementType.Length, "Length (Feet)"],
        [UnitOfMeasurementType.Volume, "Volume (Gal)"],
        [UnitOfMeasurementType.Weight, "Weight (Lb)"],
    ]);

    formState = new FormState({
        name: new FieldState('').validators(required),
        price: new FieldState<number>(null).validators(required, int),
        minLevel: new FieldState<number>(null).validators(required, int),
        notes: new FieldState<string>(''),
        productLink: new FieldState<string>('')
    });

    formNewState = new FormState({
        stockQty: new FieldState<number>(null).validators(required, int),
        unitOfMeasurement: new FieldState<UnitOfMeasurementType>(UnitOfMeasurementType.Count).validators(required),
    });

    constructor() {
        super();

        makeObservable(this, {
            inventoryItemId: observable,
            name: observable,

            save: action,
            reload: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if (this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.formState.validate();

            if (this.formState.hasError) {
                return;
            }

            if(!this.inventoryItemId) {
                await this.formNewState.validate();

                if (this.formNewState.hasError) {
                    return;
                }

                let model = new InventoryItemNewModel({
                    name: this.formState.$.name.value,
                    price: +this.formState.$.price.value,
                    minLevel: this.formState.$.minLevel.value,
                    notes: this.formState.$.notes.value,
                    productLink: this.formState.$.productLink.value,
                    stockQty: this.formNewState.$.stockQty.value,
                    unitOfMeasurement: this.formNewState.$.unitOfMeasurement.value,
                });

                this.inventoryItemId = await this.apiClients.inventoryClient.createInventoryItem(model);

                runInAction(() => {
                    this.isSavedSuccessfully = true;
                });
            } else {
                let model = new InventoryItemEditModel({
                    id: this.inventoryItemId,
                    name: this.formState.$.name.value,
                    price: +this.formState.$.price.value,
                    minLevel: this.formState.$.minLevel.value,
                    notes: this.formState.$.notes.value,
                    productLink: this.formState.$.productLink.value,
                });

                await this.apiClients.inventoryClient.updateInventoryItem(model);
                await this.reload();
            }
        });
    }

    init = async (id?: number) => {
        this.inventoryItemId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if(this.inventoryItemId > 0) {
                await this.reload();
            }
        });
    }

    reload = async () => {
        let result : InventoryItemDetailsModel = await this.apiClients.inventoryClient.getDetails(this.inventoryItemId);

        runInAction(() => {
            this.inventoryItemId = result.id;

            this.name = result.name;

            this.formState.$.name.value = result.name;
            this.formState.$.price.value = result.price;
            this.formState.$.notes.value = result.notes;
            this.formState.$.productLink.value = result.productLink;
            this.formState.$.minLevel.value = result.minLevel;
            this.formNewState.$.stockQty.value = result.stockQty;
            this.formNewState.$.unitOfMeasurement.value = result.unitOfMeasurement;
        });
    }
}
