import axios, {AxiosInstance} from "axios";

import {
    CalendarClient,
    ICalendarClient,
    IPropertyClient,
    PropertyClient,
    IPropertyContactClient,
    PropertyContactClient,
    IPolicyClient,
    PolicyClient,
    IPropertyAmenityClient,
    PropertyAmenityClient,
    IPropertyPromoCodeClient,
    PropertyPromoCodeClient,
    IntegrationClient,
    IIntegrationClient,
    IInventoryClient,
    InventoryClient
} from "../api/admin-clients.api";
import {
    DataClient,
    IDataClient,
    SearchClient,
    ISearchClient,
    PropertyClient as PropertyMainClient,
    IPropertyClient as IPropertyMainClient,
    PaymentClient,
    MarketingClient,
    IPaymentClient,
    IMarketingClient,
    IAccountClient,
    AccountClient
} from "../api/main-clients.api";
import {LocalStorageService} from "app/modules/auth/components/stores/LocalStorageService";
import {inject} from "react-ioc";
import {ApiClientConfig} from "app/modules/main/api/ApiClientConfig";

const hostName = window.location.hostname;
export const apiBaseUrl = hostName == 'localhost' || hostName == 'desktop-4fa3qhv' ? 'http://localhost:5000' :'https://solartgroupinc.com';

export class ApiClients {
    @inject localStorageService: LocalStorageService;

    adminPropertyClient: IPropertyClient = null;
    dataClient: IDataClient = null;
    axiosInstance: AxiosInstance = null;
    calendarClient: ICalendarClient = null;
    searchClient: ISearchClient = null;
    propertyMainClient: IPropertyMainClient = null;
    policyClient: IPolicyClient = null;
    propertyContactClient: IPropertyContactClient = null;
    propertyAmenityClient: IPropertyAmenityClient = null;
    paymentClient: IPaymentClient = null;
    propertyPromoCodeClient: IPropertyPromoCodeClient = null;
    marketingClient: IMarketingClient = null;
    integrationClient: IIntegrationClient = null;
    accountClient: IAccountClient = null;
    inventoryClient: IInventoryClient = null;

    constructor(configuration: ApiClientConfig) {
        this.axiosInstance = axios.create({
            baseURL: apiBaseUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            validateStatus: (status) => {
                return status >= 200 && status < 500
            }
        });

        this.axiosInstance.defaults.withCredentials = true;

        this.adminPropertyClient = new PropertyClient(configuration, apiBaseUrl, this.axiosInstance);
        this.calendarClient = new CalendarClient(configuration, apiBaseUrl, this.axiosInstance);

        this.dataClient = new DataClient(configuration, apiBaseUrl, this.axiosInstance);
        this.searchClient = new SearchClient(configuration, apiBaseUrl, this.axiosInstance);
        this.propertyMainClient = new PropertyMainClient(configuration, apiBaseUrl, this.axiosInstance);
        this.policyClient = new PolicyClient(configuration, apiBaseUrl, this.axiosInstance);
        this.propertyContactClient = new PropertyContactClient(configuration, apiBaseUrl, this.axiosInstance);
        this.paymentClient = new PaymentClient(configuration, apiBaseUrl, this.axiosInstance);
        this.propertyAmenityClient = new PropertyAmenityClient(configuration, apiBaseUrl, this.axiosInstance);
        this.propertyPromoCodeClient = new PropertyPromoCodeClient(configuration, apiBaseUrl, this.axiosInstance);
        this.marketingClient = new MarketingClient(configuration, apiBaseUrl, this.axiosInstance);
        this.integrationClient = new IntegrationClient(configuration, apiBaseUrl, this.axiosInstance);
        this.accountClient = new AccountClient(configuration, apiBaseUrl, this.axiosInstance);
        this.inventoryClient = new InventoryClient(configuration, apiBaseUrl, this.axiosInstance);

        this.axiosInstance.interceptors.response.use((response) => {
            if(response?.status == 403) {
                window.location.href = "/";
            }

            if(response?.status == 401) {
                window.location.href = "/login";
            }

            return response;
        }, (error) => {
            if(error.response?.status == 401) {
                window.location.href = "/login";
            }

            if(error.response?.status == 403) {
                window.location.href = "/";
            }
        });
    }
}
