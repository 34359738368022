import React, {Component} from "react";
import {inject, Provider} from "react-ioc";
import {BookStore, Step} from "app/modules/main/components/main/book/BookStore";
import ContactStep from "./contact";
import TermsStep from "./terms";
import Complete from "./complete";
import Payment from "./payment";
import {observer} from "mobx-react";
import {CurrentBookStore} from "app/modules/main/stores/CurrentBookStore";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {Alert, Button, Stack, Typography} from "@mui/material";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Loading} from "app/modules/common/layout/components/loading";

@Provider(BookStore)
@observer
export class Index extends Component<any, any> {
    @inject bookStore: BookStore;
    @inject currentBookStore: CurrentBookStore;

    render() {
        if(this.bookStore.goToSearch) {
            return <NavigateHook path={"/search"} />;
        }

        if(this.bookStore.isInitLoading) {
            return <Loading/>
        }

        if (!this.bookStore.bookInfo?.details) {
            return <React.Fragment>
                <BoxCenter>
                    <Stack direction={"column"}>
                        <Typography sx={{ pt: 2, pb: 3 }} variant={"h5"}>No booking details</Typography>
                        <Button variant={"text"} color={"warning"} sx={{ fontSize: "20px", width: "100%", borderRadius: 0, height: "54px" }} onClick={this.bookStore.setGoToSearch}>Search...</Button>
                    </Stack>
                </BoxCenter>
            </React.Fragment>;
        }

        return <React.Fragment>
            {(this.bookStore.errorMessage?.length > 0) ? <BoxCenter sx={{pt: 2}}><Alert severity="warning">{this.bookStore.errorMessage}</Alert></BoxCenter> : <React.Fragment>
                {this.bookStore.currentStep == Step.Contact && <ContactStep/>}
                {this.bookStore.currentStep == Step.Terms && <TermsStep/>}
                {this.bookStore.currentStep == Step.Payment && <Payment/>}
                {this.bookStore.currentStep == Step.Complete && <Complete confirmationCode={this.bookStore.confirmationCode} bookInfo={this.bookStore.bookInfo}/>}
            </React.Fragment>}
        </React.Fragment>;
    }

    async componentDidMount() {
        await this.bookStore.init();
    }
}
